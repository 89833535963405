"use client"

import Disclaimer from '@/components/Disclaimer';
import { Button } from 'antd';
import { useRouter } from 'next/navigation';
import React from 'react';

export default function Home() {

  const router = useRouter();

  const startRoulet = () => router.push('/chat-carousel');

  function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
  }

  return (
    <div className="flex flex-col items-center justify-center h-full gap-5 ">
      <main className="flex flex-col items-center gap-5 w-full flex-1 p-4 space-y-4 lg:mt-40 mt-20 h-full">
        <h1 className="text-center text-3xl md:text-4xl lg:text-5xl mb-4">
            <span className='text-[#FC4029]'>TrueCall</span> beta 0.1
        </h1>
        <h2 className='lg:text-[22px] text-[14px] text-center max-w-[750px]'>
            Challenge your friends to a game of &lsquo;Truth or Dare&rsquo; while TrueCall AI beta tracks emotions and micro muscle movements. Experience cutting-edge tech that reads the subtleties of facial expressions to detect who&apos;s keeping secrets!
        </h2>
        <Button type='primary' onClick={startRoulet} size="large" className='lg:w-[200px] lg:!h-[80px] lg:!text-[26px]'>Let&apos;s start</Button>
      </main>
      <Disclaimer/>
    </div>
  );
}
